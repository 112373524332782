import React, { useState, useCallback, useEffect } from 'react';
import { RRule, Options, Weekday } from 'rrule';
import { DateTime } from 'luxon';
import { AiTwotoneCalendar } from 'react-icons/ai';
import { BsChevronDown, BsGlobe } from 'react-icons/bs';
import { Box } from '../Box';
import { HStack } from '../Stack/HStack';
import { NumberInput, NumberInputField, Select, Input } from '../Input';
import { Text } from '../Text';
import { VStack } from '../Stack/VStack';
import { Switch } from '../Switch';
import { Button } from '../Button';
import { Spacer } from '../Spacer';
import { Icon } from '../Icon';
import { FormControl, FormLabel } from '../Form';
import { DatePickerPopover } from '../DatePickerPopover';
import { InputGroup } from '../Input/InputGroup';
import { InputRightElement } from '../Input/InputRightElement';
import { Flex } from '../Flex';
import { getCustomRuleDescription } from './scheduler.ui.utils';
import { WEEKDAYS, FREQUENCY_OPTIONS } from './scheduler.ui.constants';
import type { TimeValue } from './schduler.ui.types';

interface RecurrenceSelectorProps {
  rrule?: string;
  onChange: (rrule: string) => void;
  disabled?: boolean;
}

interface CustomRecurrenceState {
  frequency: number;
  interval: number;
  weekdays: Weekday[];
}

const parseWeekday = (day: string): Weekday => {
  switch (day) {
    case 'MO':
      return RRule.MO;
    case 'TU':
      return RRule.TU;
    case 'WE':
      return RRule.WE;
    case 'TH':
      return RRule.TH;
    case 'FR':
      return RRule.FR;
    case 'SA':
      return RRule.SA;
    case 'SU':
      return RRule.SU;
    default:
      throw new Error(`Invalid weekday: ${day}`);
  }
};

// Helper function to parse RRULE datetime string to Luxon DateTime
const parseDTStart = (dtstart: string): DateTime | null => {
  const match = dtstart.match(
    /DTSTART;TZID=([^:]+):(\d{4})(\d{2})(\d{2})T(\d{2})(\d{2})(\d{2})/
  );
  if (!match) return null;

  const [_, timezone, year, month, day, hour, minute, second] = match;
  return DateTime.fromObject(
    {
      year: parseInt(year),
      month: parseInt(month),
      day: parseInt(day),
      hour: parseInt(hour),
      minute: parseInt(minute),
      second: parseInt(second),
    },
    { zone: timezone }
  ).setZone(DateTime.local().zoneName); // Convert to local timezone
};

// Simple Time Input Component
const SimpleTimeInput: React.FC<{
  value: TimeValue;
  onChange: (value: TimeValue) => void;
  disabled?: boolean;
}> = ({ value, onChange, disabled }) => {
  // Format hours for display (e.g., 9 -> "09")
  const formatNumber = (num: string): string => num.padStart(2, '0');

  // Local state for input values before validation
  const [localHours, setLocalHours] = useState(value.hours);
  const [localMinutes, setLocalMinutes] = useState(value.minutes);

  // Handle direct hour input
  const handleHourChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newHour = e.target.value.replace(/\D/g, '');
    setLocalHours(newHour);
  };

  // Handle direct minute input
  const handleMinuteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newMinute = e.target.value.replace(/\D/g, '');
    setLocalMinutes(newMinute);
  };

  // Validate hours on blur
  const handleHourBlur = () => {
    let hourNum = parseInt(localHours);
    if (isNaN(hourNum) || hourNum < 1) hourNum = 12;
    if (hourNum > 12) hourNum = 12;

    const validHours = hourNum.toString();
    setLocalHours(validHours);
    onChange({ ...value, hours: validHours });
  };

  // Validate minutes on blur
  const handleMinuteBlur = () => {
    let minuteNum = parseInt(localMinutes);
    if (isNaN(minuteNum) || minuteNum < 0) minuteNum = 0;
    if (minuteNum > 59) minuteNum = 59;

    const validMinutes = formatNumber(minuteNum.toString());
    setLocalMinutes(validMinutes);
    onChange({ ...value, minutes: validMinutes });
  };

  // Update local state when external value changes
  useEffect(() => {
    setLocalHours(value.hours);
    setLocalMinutes(value.minutes);
  }, [value.hours, value.minutes]);

  return (
    <Flex>
      <HStack spacing={1} align="center">
        {/* Hours Input */}
        <Input
          value={localHours}
          onChange={handleHourChange}
          onBlur={handleHourBlur}
          placeholder="12"
          w="40px"
          textAlign="center"
          disabled={disabled}
        />
        <Text>:</Text>
        {/* Minutes Input */}
        <Input
          value={localMinutes}
          onChange={handleMinuteChange}
          onBlur={handleMinuteBlur}
          placeholder="00"
          w="40px"
          textAlign="center"
          disabled={disabled}
        />
        {/* AM/PM Toggle Slider */}
        <Box
          ml={2}
          position="relative"
          width="80px"
          height="32px"
          bg="gray.100"
          borderRadius="full"
          padding="4px"
          cursor={disabled ? 'not-allowed' : 'pointer'}
          onClick={() =>
            !disabled &&
            onChange({ ...value, ampm: value.ampm === 'AM' ? 'PM' : 'AM' })
          }
          opacity={disabled ? 0.6 : 1}
        >
          {/* Sliding Background */}
          <Box
            position="absolute"
            left={value.ampm === 'AM' ? '4px' : '40px'}
            top="4px"
            width="36px"
            height="24px"
            bg="primary.500"
            borderRadius="full"
            transition="left 0.2s ease"
          />
          {/* Text Labels */}
          <Flex
            position="absolute"
            inset="0"
            justify="space-between"
            align="center"
            px="14px"
          >
            <Text
              fontSize="xs"
              fontWeight="500"
              color={value.ampm === 'AM' ? 'white' : 'gray.600'}
              transition="color 0.2s ease"
              zIndex={1}
            >
              AM
            </Text>
            <Text
              fontSize="xs"
              fontWeight="500"
              color={value.ampm === 'PM' ? 'white' : 'gray.600'}
              transition="color 0.2s ease"
              zIndex={1}
            >
              PM
            </Text>
          </Flex>
        </Box>
      </HStack>
    </Flex>
  );
};

export const SchedulerRecurrenceSelector: React.FC<RecurrenceSelectorProps> = ({
  rrule,
  onChange,
  disabled,
}) => {
  const [isRecurring, setIsRecurring] = useState(false);
  const [startDate, setStartDate] = useState<DateTime>(
    DateTime.local().plus({ days: 1 }).startOf('day')
  );
  const [time, setTime] = useState<TimeValue>({
    hours: '9',
    minutes: '00',
    ampm: 'AM',
  });
  const [customState, setCustomState] = useState<CustomRecurrenceState>({
    frequency: RRule.WEEKLY,
    interval: 1,
    weekdays: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR],
  });

  useEffect(() => {
    if (!rrule) {
      const tomorrow = DateTime.local().plus({ days: 1 }).startOf('day');
      setIsRecurring(false);
      setStartDate(tomorrow);
      setTime({
        hours: '9',
        minutes: '00',
        ampm: 'AM',
      });
      return;
    }

    // First split into DTSTART and RRULE parts
    const [dtstartSection, rruleSection] = rrule.split(';RRULE:');
    const dtstartPart = dtstartSection;
    const rulePart = 'RRULE:' + rruleSection;

    if (!dtstartPart || !rulePart) {
      return;
    }

    // Parse the datetime using Luxon
    const dateTime = parseDTStart(dtstartPart);
    if (!dateTime) {
      return;
    }

    // Set the start date
    setStartDate(dateTime);

    // Convert to 12-hour format for the time picker
    const hour = dateTime.hour;
    const isPM = hour >= 12;
    const hour12 = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour;

    const timeValue: TimeValue = {
      hours: hour12.toString(),
      minutes: dateTime.minute.toString().padStart(2, '0'),
      ampm: isPM ? 'PM' : ('AM' as 'AM' | 'PM'),
    };
    setTime(timeValue);

    // Parse the RRULE part
    const ruleString = rulePart.replace('RRULE:', '');
    const ruleOptions = RRule.parseString(ruleString);

    // Check if it's a recurring rule (no COUNT=1)
    const isRecurringRule = !(ruleOptions.count === 1);
    setIsRecurring(isRecurringRule);

    if (isRecurringRule) {
      // Extract weekdays from the RRULE string directly
      const weekdayMatch = ruleString.match(/BYDAY=([^;]*)/);
      const weekdays = weekdayMatch
        ? weekdayMatch[1].split(',').map(parseWeekday)
        : [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR]; // Default to weekdays if not specified

      const newCustomState = {
        frequency: ruleOptions.freq ?? RRule.WEEKLY,
        interval: ruleOptions.interval || 1,
        weekdays,
      };
      setCustomState(newCustomState);
    }
  }, [rrule]);

  const createRuleString = useCallback(() => {
    // Convert time to 24-hour format
    const hours = parseInt(time.hours);
    const minutes = parseInt(time.minutes);
    const hour24 =
      time.ampm === 'PM'
        ? hours === 12
          ? 12
          : hours + 12
        : hours === 12
        ? 0
        : hours;

    // Create a DateTime object with the correct date and time
    const dateTime = startDate.set({
      hour: hour24,
      minute: minutes,
      second: 0,
      millisecond: 0,
    });

    // Create the DTSTART part with the local timezone
    const dtstart = `DTSTART;TZID=${dateTime.zoneName}:${dateTime.toFormat(
      "yyyyMMdd'T'HHmmss"
    )}`;

    // Create the RRULE part
    const ruleOptions: Partial<Options> = isRecurring
      ? {
          freq: customState.frequency,
          interval: customState.interval,
          byweekday: customState.weekdays,
        }
      : {
          freq: RRule.WEEKLY,
          count: 1,
        };

    const rule = new RRule(ruleOptions);
    const ruleStr = rule.toString().replace(/^RRULE:/, '');

    return `${dtstart};RRULE:${ruleStr}`;
  }, [time, startDate, isRecurring, customState]);

  useEffect(() => {
    const ruleString = createRuleString();
    onChange(ruleString);
  }, [time, customState, isRecurring, startDate, createRuleString, onChange]);

  return (
    <Box>
      <VStack spacing={6} align="stretch">
        <HStack spacing={6} align="flex-start">
          <FormControl flex={1}>
            <FormLabel fontSize={14} fontWeight={500} color="gray.900">
              Start Date
            </FormLabel>
            <DatePickerPopover
              value={startDate.toJSDate()}
              onChange={(newDate) => {
                if (newDate) {
                  setStartDate(DateTime.fromJSDate(newDate));
                }
              }}
              futureOnly={true}
            >
              <Button
                variant="outlineGray"
                leftIcon={<Icon as={AiTwotoneCalendar} />}
                justifyContent="start"
                w="full"
              >
                {startDate.toLocaleString()}
                <Spacer />
                <Icon as={BsChevronDown} />
              </Button>
            </DatePickerPopover>
          </FormControl>

          <FormControl flex={1}>
            <FormLabel fontSize={14} fontWeight={500} color="gray.900">
              Time
            </FormLabel>
            <SimpleTimeInput
              value={time}
              onChange={setTime}
              disabled={disabled}
            />
          </FormControl>
        </HStack>

        <VStack spacing={2} align="stretch">
          <HStack spacing={2}>
            <FormLabel mb={0} fontSize={14} fontWeight={500} color="gray.900">
              Recurring
            </FormLabel>
            <Switch
              isChecked={isRecurring}
              onChange={(e) => {
                setIsRecurring(e.target.checked);
              }}
            />
          </HStack>

          <Text fontSize="sm" color="gray.600">
            {getCustomRuleDescription(
              customState,
              isRecurring,
              startDate.toJSDate(),
              time
            )}{' '}
            <Text as="span" fontSize="xs">
              ({startDate.zoneName})
            </Text>
          </Text>
        </VStack>

        {isRecurring && (
          <Box>
            <VStack spacing={4} align="stretch">
              <HStack spacing={4} align="flex-end">
                <FormControl flex={2}>
                  <FormLabel fontSize={14} fontWeight={500} color="gray.900">
                    Repeat every
                  </FormLabel>
                  <NumberInput
                    value={customState.interval}
                    onChange={(_, value) =>
                      setCustomState((prev) => ({
                        ...prev,
                        interval: value,
                      }))
                    }
                    min={1}
                    isDisabled={disabled}
                  >
                    <NumberInputField />
                  </NumberInput>
                </FormControl>

                <FormControl flex={3}>
                  <Select
                    value={customState.frequency}
                    onChange={(e) =>
                      setCustomState((prev) => ({
                        ...prev,
                        frequency: parseInt(e.target.value),
                      }))
                    }
                    isDisabled={disabled}
                  >
                    {FREQUENCY_OPTIONS.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </HStack>

              {customState.frequency === RRule.WEEKLY && (
                <FormControl>
                  <FormLabel fontSize={14} fontWeight={500} color="gray.900">
                    On
                  </FormLabel>
                  <HStack spacing={2} justify="flex-start">
                    {WEEKDAYS.map((day) => {
                      const isSelected = customState.weekdays.includes(
                        day.value
                      );
                      return (
                        <Button
                          key={day.value.toString()}
                          size="sm"
                          width="40px"
                          height="40px"
                          variant="unstyled"
                          bg={isSelected ? 'primary.500' : 'white'}
                          color={isSelected ? 'white' : 'primary.500'}
                          border="1px solid"
                          borderColor="primary.500"
                          borderRadius="md"
                          _hover={{
                            bg: isSelected ? 'primary.600' : 'primary.50',
                            transform: 'translateY(-1px)',
                          }}
                          _active={{
                            transform: 'translateY(0)',
                          }}
                          transition="all 0.2s"
                          onClick={() => {
                            setCustomState((prev) => ({
                              ...prev,
                              weekdays: prev.weekdays.includes(day.value)
                                ? prev.weekdays.filter((d) => d !== day.value)
                                : [...prev.weekdays, day.value],
                            }));
                          }}
                          isDisabled={disabled}
                        >
                          {day.label}
                        </Button>
                      );
                    })}
                  </HStack>
                </FormControl>
              )}
            </VStack>
          </Box>
        )}
      </VStack>
    </Box>
  );
};
