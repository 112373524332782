import { useCallback } from 'react';
import {
  StackProps,
  VStack,
  Text,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  Square,
  HStack,
} from '@chakra-ui/react';
import { BsThreeDots, BsClockHistory } from 'react-icons/bs';
import {
  MdDateRange,
  MdOutlineModeEditOutline,
  MdAutoMode,
} from 'react-icons/md';
import { BiTrashAlt } from 'react-icons/bi';
import { FaRegComments } from 'react-icons/fa';
import { VscFeedback } from 'react-icons/vsc';
import { FiAlertOctagon } from 'react-icons/fi';
import { Tooltip } from '@spoke/common';
import { BoardAsyncSchedule } from '@spoke/graphql';
import { getRRuleDisplayText } from '@spoke/common/lib/ui/scheduler/scheduler.ui.utils';

type AsyncFeedbackConfigListProps = StackProps & {
  asyncFeedbackConfigs: BoardAsyncSchedule[];
  onConfigClick: (config: BoardAsyncSchedule) => void;
  onDeleteConfig?: (configId: string) => void;
  onTriggerClick?: (configId: string) => void;
  disableActions?: boolean;
  maxHeight?: string;
};

const FeedbackListItem = ({
  config,
  disableActions,
  onConfigClick,
  onTriggerClick,
  onDeleteConfig,
}: {
  config: BoardAsyncSchedule;
  disableActions: boolean;
  onConfigClick: (config: BoardAsyncSchedule) => void;
  onTriggerClick?: (configId: string) => void;
  onDeleteConfig?: (configId: string) => void;
}) => (
  <Box
    key={config.id}
    p={{ base: 3, md: 4 }}
    borderWidth="1px"
    borderRadius="md"
    display="flex"
    flexDirection="column"
    gap={3}
    bg="white"
    _hover={{ shadow: 'sm' }}
    transition="box-shadow 0.2s"
  >
    {/* Top Row: Title and Actions */}
    <HStack justifyContent="space-between" alignItems="center">
      <HStack spacing={2} flex={1}>
        <Icon
          as={
            config.feedbackType === 'STANDUP' ? BsClockHistory : FaRegComments
          }
          color="primary.600"
          boxSize={5}
        />
        <Text
          color="gray.700"
          fontWeight={600}
          fontSize="md"
          onClick={() => !disableActions && onConfigClick(config)}
          cursor={disableActions ? 'default' : 'pointer'}
          _hover={{
            textDecoration: disableActions ? 'none' : 'underline',
          }}
        >
          {config.name}
        </Text>
        {!config.isActive && (
          <Tooltip
            label="Edit or archive this feedback schedule because it already happened"
            placement="top"
            variant="white"
          >
            <Icon as={FiAlertOctagon} color="red.500" />
          </Tooltip>
        )}
      </HStack>

      {/* Actions Menu */}
      {!disableActions && (
        <Menu>
          <MenuButton
            as={Square}
            size={8}
            borderRadius="md"
            _hover={{ bg: 'primary.50' }}
          >
            <Icon as={BsThreeDots} color="primary.600" />
          </MenuButton>
          <MenuList>
            <MenuItem
              icon={<Icon as={MdOutlineModeEditOutline} color="primary.600" />}
              onClick={() => onConfigClick(config)}
            >
              Edit
            </MenuItem>
            {onTriggerClick && (
              <MenuItem
                icon={<Icon as={MdAutoMode} color="primary.600" />}
                onClick={() => onTriggerClick(config.id)}
              >
                Trigger Now
              </MenuItem>
            )}
            {onDeleteConfig && (
              <MenuItem
                icon={<Icon as={BiTrashAlt} color="primary.600" />}
                onClick={() => onDeleteConfig(config.id)}
              >
                Delete
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      )}
    </HStack>

    <HStack spacing={2} alignItems="flex-start">
      <Icon as={MdDateRange} color="gray.500" boxSize={4} mt={1} />
      <Text
        color="gray.600"
        fontSize="sm"
        fontWeight={500}
        whiteSpace="pre-line"
        lineHeight="tall"
      >
        {getRRuleDisplayText(config.rrule || '')}
      </Text>
    </HStack>
  </Box>
);

export const AsyncFeedbackConfigList = ({
  asyncFeedbackConfigs,
  onConfigClick,
  onDeleteConfig,
  onTriggerClick,
  disableActions = false,
  maxHeight = '200px',
  ...rest
}: AsyncFeedbackConfigListProps) => {
  const handleConfigClick = useCallback(
    (config: BoardAsyncSchedule) => onConfigClick(config),
    [onConfigClick]
  );

  return (
    <VStack
      w="full"
      alignItems="stretch"
      mt={4}
      spacing={3}
      overflowY="auto"
      maxHeight={maxHeight}
      pb={asyncFeedbackConfigs.length ? 4 : 0}
      {...rest}
    >
      {asyncFeedbackConfigs?.map(
        (config) =>
          config && (
            <FeedbackListItem
              key={config.id}
              config={config}
              disableActions={disableActions}
              onConfigClick={handleConfigClick}
              onTriggerClick={onTriggerClick}
              onDeleteConfig={onDeleteConfig}
            />
          )
      )}
    </VStack>
  );
};
