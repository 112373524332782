import React, { FC, useState, useCallback, useEffect } from 'react';
import { useForm, useWatch, useFieldArray } from 'react-hook-form';
import { MdClose, MdEmail } from 'react-icons/md';
import { AnimatePresence } from 'framer-motion';
import { AiTwotoneCalendar } from 'react-icons/ai';
import { BsChevronDown, BsClockHistory } from 'react-icons/bs';
import { FaRegComments, FaSlack } from 'react-icons/fa';
import { format } from 'date-fns';
import { SiMicrosoftteams } from 'react-icons/si';
import {
  BoardAsyncCollectionType,
  BoardAsyncFeedbackType,
  BoardAsyncQuestionType,
  BoardAsyncSchedule,
  RetrospectiveFormat,
  useAllRetroFormatsQuery,
  useCreateBoardAsyncScheduleMutation,
  useUpdateBoardAsyncScheduleMutation,
} from '@spoke/graphql';
import { useCurrentTeam } from '@spoke/user';
import {
  SpokeFormRules,
  useDisclosure,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  HStack,
  Icon,
  Heading,
  ModalBody,
  Flex,
  VStack,
  Modal,
  ModalOverlay,
  Text,
  FormControl,
  FormLabel,
  Select,
  Option,
  FormErrorMessage,
  Spacer,
  Switch,
  Button,
  Input,
  Box,
  MotionFlex,
  DatePickerPopover,
  Link,
  useCurrentUser,
  InfoTooltip,
  InputGroup,
  InputRightElement,
  TimeValue,
  SchedulerRecurrenceSelector,
} from '@spoke/common';
import { useInstalledOrgIntegrations } from '@spoke/metrics';

const FEEDBACK_CHANNELS = [
  {
    value: 'SLACK',
    label: 'Slack',
    icon: FaSlack,
    disabled: (slack: boolean) => !slack,
  },
  {
    value: 'TEAMS',
    label: 'Teams',
    icon: SiMicrosoftteams,
    disabled: () => true, // Currently always disabled
  },
  {
    value: 'EMAIL',
    label: 'Email',
    icon: MdEmail,
    disabled: () => false,
  },
];

const FEEDBACK_TYPES = [
  {
    value: 'STANDUP',
    label: 'Standup',
    icon: BsClockHistory,
    timeSaving: 'Save ~8 hrs/month',
    description: 'Keep your team aligned without disrupting flow',
  },
  {
    value: 'RETROSPECTIVE',
    label: 'Retrospective',
    icon: FaRegComments,
    timeSaving: 'Save ~2 hrs/month',
    description: 'Gather thoughtful feedback for continuous improvement',
  },
];

export type BoardAsyncFeedbackFormSchema = {
  id?: string;
  teamId: string;
  name: string;
  rrule: string;
  collectionTime: TimeValue | null;
  feedbackCollectionType: string;
  feedbackType: string;
  maxQuestions?: number;
  retrospectiveFormatId?: string;
  sendReminders: boolean;
  areQuestionsOptional: boolean;
  isActive: boolean;
  questions: { id?: string; question: string; isOptional: boolean }[];
};

const rules: SpokeFormRules<BoardAsyncFeedbackFormSchema> = {
  name: { required: 'Name is required' },
  teamId: { required: 'Team ID is required' },
  collectionTime: {
    required: 'Collection time is required',
    validate: (value: TimeValue | null) =>
      value !== null || 'Collection time is required',
  },
  feedbackCollectionType: { required: 'Feedback channel is required' },
  feedbackType: { required: 'Feedback type is required' },
  sendReminders: {},
  areQuestionsOptional: {},
  isActive: {},
  id: {},
  rrule: { required: 'Schedule is required' },
  maxQuestions: { required: 'Max questions is required' },
  retrospectiveFormatId: {},
  questions: {
    validate: (questions) =>
      questions.length > 0 && questions.length <= 10
        ? true
        : 'You must define between 1 and 10 questions',
  },
};

type AsyncFeedbackConfigModalProps = {
  config?: BoardAsyncSchedule;
  onGoBack: () => void;
  isOpen: boolean;
  onClose: () => void;
};

export const AsyncFeedbackConfigModalContent: FC<
  AsyncFeedbackConfigModalProps
> = ({ config, onGoBack, isOpen, onClose }) => {
  const [createBoardAsyncSchedule] = useCreateBoardAsyncScheduleMutation();
  const [updateBoardAsyncSchedule] = useUpdateBoardAsyncScheduleMutation();
  const [team] = useCurrentTeam();
  const [user] = useCurrentUser();
  const { loading, slack } = useInstalledOrgIntegrations();

  const retroFormatsQuery = useAllRetroFormatsQuery();
  const retroFormats =
    (retroFormatsQuery.data?.retrospectiveFormats as RetrospectiveFormat[]) ??
    null;

  const defineYourOwnQuestionsDisclosure = useDisclosure(
    config?.questionType === BoardAsyncQuestionType.Custom || !config
  );
  const useRetrospectiveTemplateDisclosure = useDisclosure(
    config?.questionType === BoardAsyncQuestionType.Template
  );

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    setValue,
    setError,
    getValues,
    control,
  } = useForm<BoardAsyncFeedbackFormSchema>({
    defaultValues: {
      id: config?.id || '',
      teamId: config?.teamId || team?.id || '',
      name: config?.name || '',
      rrule: config?.rrule || '',
      feedbackCollectionType:
        config?.feedbackCollectionType?.toString() || 'EMAIL',
      feedbackType: config?.feedbackType?.toString() || 'STANDUP',
      maxQuestions: config?.maxQuestions || undefined,
      retrospectiveFormatId: config?.retrospectiveFormatId || undefined,
      sendReminders: config?.sendReminders,
      areQuestionsOptional: config?.areQuestionsOptional,
      isActive: config?.isActive,
      questions: config?.customQuestions || [
        { question: '', isOptional: false },
      ],
    },
    mode: 'onChange',
  });

  const handleRRuleChange = (newRRule: string) => {
    setValue('rrule', newRRule);
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'questions',
  });

  const onSubmit = useCallback(
    async (form: BoardAsyncFeedbackFormSchema) => {
      const validateCollectionTime = (value: TimeValue | null) => {
        if (value === null) {
          setError('collectionTime', {
            type: 'manual',
            message: 'Choose a time to collect feedback',
          });
          return false;
        } else {
          setError('collectionTime', {
            type: 'manual',
            message: '',
          });
          return true;
        }
      };

      const validateQuestions = (questions: { question: string }[]) => {
        if (defineYourOwnQuestionsDisclosure.isOpen) {
          const hasValidQuestion = questions.some(
            (q) => q.question.trim().length > 0
          );
          if (!hasValidQuestion) {
            setError('questions', {
              type: 'manual',
              message: 'You must provide at least 1 question to ask your team',
            });
            return false;
          }
        }
        return true;
      };

      const filteredQuestions = form.questions
        .filter((q) => q.question.trim().length > 0)
        .map((q, index) => ({
          id: q.id || undefined,
          question: q.question,
          isOptional: q.isOptional,
          index,
        }));

      const isCollectionTimeValid = validateCollectionTime(form.collectionTime);
      const areQuestionsValid = validateQuestions(form.questions);

      if (
        !isCollectionTimeValid ||
        !areQuestionsValid ||
        Object.keys(errors).length > 0
      ) {
        return;
      }

      let questionType: BoardAsyncQuestionType =
        BoardAsyncQuestionType.AutoGenerate;

      if (defineYourOwnQuestionsDisclosure.isOpen) {
        questionType = BoardAsyncQuestionType.Custom;
      } else if (useRetrospectiveTemplateDisclosure.isOpen) {
        questionType = BoardAsyncQuestionType.Template;
      }

      const scheduleData = {
        teamId: team?.id || '',
        ownerId: user?.id || '',
        name: form.name,
        rrule: form.rrule,
        feedbackCollectionType:
          form.feedbackCollectionType as BoardAsyncCollectionType,
        questionType,
        maxQuestions: form.maxQuestions,
        retrospectiveFormatId: form.retrospectiveFormatId,
        sendReminders: form.sendReminders,
        areQuestionsOptional: form.areQuestionsOptional,
        feedbackType: form.feedbackType as BoardAsyncFeedbackType,
      };

      if (config) {
        await updateBoardAsyncSchedule({
          variables: {
            id: form.id || '',
            data: scheduleData,
            customQuestions: filteredQuestions,
          },
        });
      } else {
        await createBoardAsyncSchedule({
          variables: {
            data: scheduleData,
            customQuestions: filteredQuestions,
          },
        });
      }
      onGoBack();
    },
    [
      config,
      createBoardAsyncSchedule,
      updateBoardAsyncSchedule,
      defineYourOwnQuestionsDisclosure.isOpen,
      useRetrospectiveTemplateDisclosure.isOpen,
      team?.id,
      onGoBack,
      user?.id,
      errors,
      setError,
    ]
  );

  const onDefineYourOwnQuestions = async () => {
    defineYourOwnQuestionsDisclosure.toggle();
    useRetrospectiveTemplateDisclosure.toggle();
  };
  const onUseRetrospectiveTemplate = async () => {
    useRetrospectiveTemplateDisclosure.toggle();
    defineYourOwnQuestionsDisclosure.toggle();
  };

  const [selectedRetrospectiveFormat, setSelectedRetrospectiveFormat] =
    useState<string | undefined>(config?.retrospectiveFormatId || undefined);

  const handleRetrospectiveFormatChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newFormat = event.target.value;
    setValue('retrospectiveFormatId', newFormat);
    setSelectedRetrospectiveFormat(newFormat);
  };

  const selectedFormatColumns = retroFormats?.find(
    (retroFormat) => retroFormat.slug === selectedRetrospectiveFormat
  )?.columnNames;

  const handleFeedbackTypeChange = (typeValue: string) => {
    setValue('feedbackType', typeValue, { shouldValidate: true });
    if (typeValue === 'STANDUP') {
      defineYourOwnQuestionsDisclosure.open();
      useRetrospectiveTemplateDisclosure.close();
      setValue('retrospectiveFormatId', undefined, { shouldValidate: true });
    } else if (typeValue === 'RETROSPECTIVE') {
      defineYourOwnQuestionsDisclosure.close();
      useRetrospectiveTemplateDisclosure.open();
      setValue('questions', [{ question: '', isOptional: false }], {
        shouldValidate: true,
      });
    }
  };

  return (
    <ModalContent p={8} maxW={700}>
      <ModalCloseButton />
      <ModalHeader p={0} mb={4}>
        <HStack>
          <Heading mb={1} fontSize={24} color="gray.900">
            {config ? `Edit Feedback Schedule` : 'Set Up Feedback Schedule'}
          </Heading>
        </HStack>
      </ModalHeader>
      <ModalBody p={0}>
        <VStack spacing={4} justifyContent="space-between" alignItems="stretch">
          <Text fontSize="15" color="gray.500">
            Automate feedback collection from your team for your team standups
            and retrospectives. You can{' '}
            <Link
              color="primary.500"
              href="/integrations"
              isExternal
              target="_blank"
              rel="noopener noreferrer"
            >
              configure channels
            </Link>{' '}
            or{' '}
            <Link
              color="primary.500"
              href="https://www.scatterspoke.com/kb-cat/asynchronous-feedback"
              isExternal
              target="_blank"
              rel="noopener noreferrer"
            >
              learn more
            </Link>{' '}
            about feedback collection options.
          </Text>
          <FormControl isInvalid={Boolean(errors.name)}>
            <FormLabel fontSize={15} htmlFor="name">
              Name
            </FormLabel>
            <Input
              id="name"
              placeholder="We will append the date to make finding the results easier"
              minH={10}
              alignItems="center"
              autoFocus
              {...register('name', rules.name)}
            />
            <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
          </FormControl>
          <FormControl mt={4}>
            <HStack>
              <FormLabel htmlFor="feedbackType">Type of Feedback</FormLabel>
              <InfoTooltip
                ml={-4}
                mb={1}
                maxW={300}
                size={14}
                text="Tags the feedback with the type of feedback so you can filter and analyze it later."
              />
            </HStack>
            <HStack spacing={4} width="full">
              {FEEDBACK_TYPES.map((type) => (
                <Button
                  key={type.value}
                  variant="unstyled"
                  width="50%"
                  height="auto"
                  p={6}
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  bg={
                    getValues('feedbackType') === type.value
                      ? 'primary.50'
                      : 'white'
                  }
                  border="1px solid"
                  borderColor={
                    getValues('feedbackType') === type.value
                      ? 'primary.500'
                      : 'gray.200'
                  }
                  borderRadius="lg"
                  _hover={{
                    borderColor: 'primary.500',
                  }}
                  _active={{
                    transform: 'translateY(0)',
                  }}
                  transition="all 0.2s"
                  onClick={() => handleFeedbackTypeChange(type.value)}
                  pointerEvents="auto"
                  cursor="pointer"
                >
                  <HStack
                    spacing={3}
                    mb={2}
                    width="full"
                    alignItems="flex-start"
                  >
                    <Icon
                      as={type.icon}
                      boxSize={5}
                      color={
                        getValues('feedbackType') === type.value
                          ? 'primary.500'
                          : 'gray.500'
                      }
                    />
                    <Text
                      fontSize="lg"
                      fontWeight="500"
                      color="gray.900"
                      textAlign="left"
                    >
                      {type.label}
                    </Text>
                  </HStack>
                  <Text
                    fontSize="sm"
                    color="primary.500"
                    fontWeight="500"
                    mb={2}
                    textAlign="left"
                  >
                    {type.timeSaving}
                  </Text>
                  <Text
                    fontSize="sm"
                    color="gray.600"
                    width="full"
                    whiteSpace="normal"
                    wordBreak="break-word"
                    textAlign="left"
                  >
                    {type.description}
                  </Text>
                </Button>
              ))}
            </HStack>
          </FormControl>
          <HStack spacing={4}>
            <FormControl>
              <HStack>
                <FormLabel htmlFor="feedbackSource">Feedback Channel</FormLabel>
                <InfoTooltip
                  ml={-4}
                  mb={1}
                  maxW={300}
                  size={14}
                  text="Choose the channel you want to collect feedback from. You can configure channels in the Integrations tab."
                />
              </HStack>
              <VStack spacing={2} align="stretch">
                <HStack spacing={2}>
                  {FEEDBACK_CHANNELS.map((channel) => (
                    <Button
                      key={channel.value}
                      size="md"
                      width="120px"
                      height="40px"
                      variant="unstyled"
                      bg={
                        getValues('feedbackCollectionType') === channel.value
                          ? 'primary.500'
                          : 'white'
                      }
                      color={
                        getValues('feedbackCollectionType') === channel.value
                          ? 'white'
                          : 'primary.500'
                      }
                      border="1px solid"
                      borderColor="primary.500"
                      borderRadius="md"
                      _hover={{
                        bg:
                          getValues('feedbackCollectionType') === channel.value
                            ? 'primary.600'
                            : 'primary.50',
                      }}
                      _active={{
                        transform: 'translateY(0)',
                      }}
                      transition="all 0.2s"
                      leftIcon={<Icon as={channel.icon} />}
                      isDisabled={channel.disabled(slack)}
                      onClick={() =>
                        setValue('feedbackCollectionType', channel.value)
                      }
                      {...register('feedbackCollectionType')}
                    >
                      {channel.label}
                    </Button>
                  ))}
                </HStack>
                {getValues('feedbackCollectionType') !== 'EMAIL' && (
                  <Text fontSize="xs" color="gray.500">
                    We will fall back to email for team members not mapped to{' '}
                    {getValues('feedbackCollectionType').toLowerCase()}
                  </Text>
                )}
              </VStack>
            </FormControl>
          </HStack>

          <Text fontSize="15" fontWeight="500">
            What questions to ask?
          </Text>
          {getValues('feedbackType') === 'STANDUP' && (
            <Box
              borderWidth={1}
              borderRadius="lg"
              borderColor="gray.200"
              mt={4}
              p={4}
              maxHeight={
                defineYourOwnQuestionsDisclosure.isOpen ? 'auto' : '50px'
              }
              transition="max-height 0.2s ease-out"
              overflow="hidden"
            >
              <Flex cursor="pointer" gap={1}>
                <Heading fontSize={14} fontWeight={500} color="gray.900">
                  Define your questions
                </Heading>
                <Spacer />
                <Switch
                  onChange={onDefineYourOwnQuestions}
                  isChecked={defineYourOwnQuestionsDisclosure.isOpen}
                />
              </Flex>
              <AnimatePresence>
                {defineYourOwnQuestionsDisclosure.isOpen && (
                  <MotionFlex
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    direction="column"
                  >
                    <Text
                      fontSize={14}
                      fontWeight={400}
                      color="gray.500"
                      mt={2}
                    >
                      Choose up to 10 questions to ask your team.
                    </Text>
                    <FormControl isInvalid={Boolean(errors.questions?.message)}>
                      <Box maxHeight="300px" overflowY="auto">
                        {fields.map((field, index) => (
                          <FormControl mt={2} key={field.id}>
                            <FormLabel htmlFor={`questions[${index}].question`}>
                              Question {index + 1}
                            </FormLabel>

                            <HStack justifyContent="space-between" mt={2}>
                              <InputGroup>
                                <Input
                                  id={`questions[${index}].question`}
                                  placeholder={`Enter question ${index + 1}`}
                                  {...register(
                                    `questions.${index}.question` as const
                                  )}
                                />
                                {fields.length > 1 && (
                                  <InputRightElement>
                                    <Icon
                                      as={MdClose}
                                      cursor="pointer"
                                      onClick={() => remove(index)}
                                    />
                                  </InputRightElement>
                                )}
                              </InputGroup>
                              {/* <Spacer /> */}
                              {/* <FormLabel
                                htmlFor={`questions[${index}].optional`}
                              >
                                Optional
                              </FormLabel>
                              <Switch
                                id={`questions[${index}].optional`}
                                {...register(
                                  `questions.${index}.isOptional` as const
                                )}
                              /> */}
                            </HStack>
                          </FormControl>
                        ))}
                      </Box>
                      <FormErrorMessage>
                        {errors?.questions?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <Button
                      mt={4}
                      variant="outlineGray"
                      onClick={() =>
                        append({ question: '', isOptional: false })
                      }
                      isDisabled={fields.length >= 10}
                    >
                      Add Question
                    </Button>
                  </MotionFlex>
                )}
              </AnimatePresence>
            </Box>
          )}
          {getValues('feedbackType') === 'RETROSPECTIVE' && (
            <Box
              borderWidth={1}
              borderRadius="lg"
              borderColor="gray.200"
              mt={4}
              p={4}
              maxHeight={
                useRetrospectiveTemplateDisclosure.isOpen ? 'auto' : '50px'
              }
              transition="max-height 0.2s ease-out"
              overflow="hidden"
            >
              <Flex cursor="pointer" gap={1}>
                <Heading fontSize={14} fontWeight={500} color="gray.900">
                  Use Retrospective Template
                </Heading>
                <Spacer />
                <Switch
                  onChange={onUseRetrospectiveTemplate}
                  isChecked={useRetrospectiveTemplateDisclosure.isOpen}
                />
              </Flex>
              <AnimatePresence>
                {useRetrospectiveTemplateDisclosure.isOpen && (
                  <MotionFlex
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2 }}
                  >
                    <VStack spacing={4}>
                      <Text
                        fontSize={14}
                        fontWeight={400}
                        color="gray.500"
                        mt={2}
                        alignSelf="start"
                      >
                        Choose from our pre-built retrospective templates.
                      </Text>
                      <FormControl>
                        <FormLabel htmlFor="retrospectiveFormat">
                          Retrospective Format
                        </FormLabel>
                        <Select
                          id="retrospectiveFormat"
                          placeholder="Select a format"
                          value={selectedRetrospectiveFormat}
                          onChange={handleRetrospectiveFormatChange}
                          // {...register('retrospectiveFormatId')}
                        >
                          {retroFormats?.map(
                            (retroFormat: RetrospectiveFormat) => (
                              <Option
                                key={retroFormat.slug}
                                value={retroFormat.slug}
                              >
                                {retroFormat.displayName}
                              </Option>
                            )
                          )}
                        </Select>
                        <FormErrorMessage>
                          {errors.retrospectiveFormatId && 'Format is required'}
                        </FormErrorMessage>
                      </FormControl>
                      {selectedFormatColumns && (
                        <Text
                          fontSize={14}
                          fontWeight={400}
                          color="gray.600"
                          mt={2}
                          alignSelf="start"
                        >
                          Prompts:{' '}
                          {selectedFormatColumns
                            .filter(
                              (column): column is string =>
                                column !== null && column !== undefined
                            )
                            .map(
                              (
                                column: string,
                                index: number,
                                array: string[]
                              ) => (
                                <React.Fragment key={`${column}-${index}`}>
                                  {column}
                                  {index < array.length - 1 ? ', ' : ''}
                                </React.Fragment>
                              )
                            )}
                        </Text>
                      )}
                    </VStack>
                  </MotionFlex>
                )}
              </AnimatePresence>
            </Box>
          )}

          <Text fontSize="15" fontWeight="500" mb={4}>
            When to collect feedback?
          </Text>
          <Box borderWidth={1} borderRadius="lg" borderColor="gray.200" p={6}>
            <FormControl isInvalid={!!errors.rrule}>
              <SchedulerRecurrenceSelector
                rrule={getValues('rrule')}
                onChange={handleRRuleChange}
                disabled={isSubmitting}
              />
              <FormErrorMessage>{errors.rrule?.message}</FormErrorMessage>
            </FormControl>
          </Box>

          <Flex ml="auto" mt={6} justifyContent="end" gap={2}>
            <Button
              size="lg"
              variant="outlineGray"
              type="submit"
              w="fit-content"
              onClick={onGoBack}
              isDisabled={isSubmitting}
            >
              Back
            </Button>
            <Button
              size="lg"
              w="fit-content"
              type="submit"
              isLoading={isSubmitting}
              onClick={() => handleSubmit(onSubmit)()}
            >
              {config ? 'Update' : 'Create'}
            </Button>
          </Flex>
        </VStack>
      </ModalBody>
    </ModalContent>
  );
};

export const AsyncFeedbackConfigModal: FC<AsyncFeedbackConfigModalProps> = (
  props
) => (
  <Modal isOpen={props.isOpen} onClose={props.onClose}>
    <ModalOverlay />
    <AsyncFeedbackConfigModalContent {...props} />
  </Modal>
);
