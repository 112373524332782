import { useState, FC } from 'react';
import { AsyncFeedbackListModalBody } from './AsyncFeedbackListModal';
import { AsyncFeedbackConfigModalContent } from './AsyncFeedbackConfigModal';
import { Modal, ModalOverlay, ModalProps, useToast } from '@spoke/common';
import {
  BoardAsyncSchedule,
  useArchiveBoardAsyncScheduleMutation,
  useBoardAsyncSchedulesByTeamIdQuery,
  useTriggerBoardAsyncScheduleByIdMutation,
} from '@spoke/graphql';
import { useCurrentTeam } from '@spoke/user';

type AsyncFeedbackModalProps = Omit<ModalProps, 'children'>;

export const AsyncFeedbackModalContent: FC<AsyncFeedbackModalProps> = ({
  onClose,
}) => {
  const [currentTeam] = useCurrentTeam();
  const [toast] = useToast();
  const { data, loading, error, refetch } = useBoardAsyncSchedulesByTeamIdQuery(
    {
      variables: { teamId: currentTeam?.id ?? '' },
    }
  );

  const [triggerBoardAsyncScheduleById] =
    useTriggerBoardAsyncScheduleByIdMutation();

  const [archiveBoardAsyncSchedule] = useArchiveBoardAsyncScheduleMutation();

  const asyncFeedbackConfigs = data?.boardAsyncSchedulesByTeamId;

  const [createConfigClicked, setCreateConfigClicked] = useState(false);
  const [selectedConfig, setSelectedConfig] =
    useState<BoardAsyncSchedule | null>(null);

  const handleGoBack = () => {
    setCreateConfigClicked(false);
    setSelectedConfig(null);
    refetch();
  };

  const handleConfigSelect = (config: BoardAsyncSchedule) => {
    setSelectedConfig(config);
  };

  const handleDeleteConfig = (configId: string) => {
    setSelectedConfig(null);
    archiveBoardAsyncSchedule({ variables: { id: configId } }).then(() => {
      refetch();
    });
  };

  const onTriggerClick = (configId: string) => {
    triggerBoardAsyncScheduleById({ variables: { id: configId } })
      .then(() => {
        toast({
          title: 'Async feedback triggered',
          description: 'The feedback collection has been started successfully',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        refetch();
      })
      .catch((err) => {
        toast({
          title: 'Failed to trigger feedback',
          description:
            'An error occurred while triggering the feedback collection',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  if (!selectedConfig && !createConfigClicked) {
    return (
      <AsyncFeedbackListModalBody
        asyncFeedbackConfigs={asyncFeedbackConfigs ?? []}
        onCreateConfig={() => setCreateConfigClicked(true)}
        onConfigClick={handleConfigSelect}
        onDeleteConfig={handleDeleteConfig}
        onTriggerClick={onTriggerClick}
        isLoading={loading}
      />
    );
  }

  return (
    <AsyncFeedbackConfigModalContent
      config={selectedConfig ?? undefined}
      onGoBack={handleGoBack}
      isOpen={true}
      onClose={onClose}
    />
  );
};

export const AsyncFeedbackModal: FC<AsyncFeedbackModalProps> = (props) => (
  <Modal {...props}>
    <ModalOverlay>
      <AsyncFeedbackModalContent {...props} />
    </ModalOverlay>
  </Modal>
);
