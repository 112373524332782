import { FetchResult } from '@apollo/client';
import { motion } from 'framer-motion';
import { GraphQLError } from 'graphql';
import { FC, useState, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import {
  useCreateUserMutation,
  useOneTimePasswordLoginMutation,
  useUpdateUserMutation,
  OneTimePasswordLoginMutation,
  UpdateUserMutation,
  User,
} from '@spoke/graphql';
import {
  SpokeFormRules,
  EMAIL_PATTERN,
  useCurrentBoardId,
  isSsoUserError,
  isInvalidOtpError,
  SpkHead,
  Card,
  VStack,
  Heading,
  FormControl,
  HStack,
  FormLabel,
  FormErrorMessage,
  Button,
  SpkLink,
  SPOKE_TERMS_OF_SERVICE,
  SPOKE_PRIVACY_POLICY,
  Divider,
  OracleLogo,
  SalesforceLogo,
  FedexLogo,
  Link,
  Input,
  log,
  Image,
  Text,
  useRouter,
  useAssets,
} from '@spoke/common';

type AuthRegisterProps = {
  greeting?: string;
  onContinueWithEmailPassword: () => void;
  onSuccess: (user: User) => void;
  onSsoUserDetected: () => void;
  onSelectMethod?: () => void;
};

export type SignupFormSchema = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
};

const rules: SpokeFormRules<SignupFormSchema> = {
  firstName: { required: 'First name is required' },
  lastName: { required: 'Last name is required' },
  email: {
    required: 'Email is required',
    pattern: { value: EMAIL_PATTERN, message: 'Please enter a valid email' },
  },
  password: { required: 'Password is required' },
};

export const AuthRegister: FC<AuthRegisterProps> = ({
  greeting = 'Create your account',
  onContinueWithEmailPassword,
  onSuccess,
  onSsoUserDetected,
  onSelectMethod,
}) => {
  const router = useRouter();

  const [createUser] = useCreateUserMutation();
  const [loginWithOtp] = useOneTimePasswordLoginMutation();
  const [updateUser] = useUpdateUserMutation();
  const [error, setError] = useState('');

  const [boardId] = useCurrentBoardId();

  const { illustrationHello } = useAssets();

  const [emailFromQueryParams, setEmailFromQueryParams] = useState(
    router.query.email as string | undefined
  );

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isValid },
    setValue,
  } = useForm<SignupFormSchema>();

  useEffect(() => {
    if (router.query.email) {
      setEmailFromQueryParams(router.query.email as string);
      setValue('email', router.query.email as string);
    }
  }, [router.query.email, setValue]);

  const handleUserUpdate = useCallback(
    async (
      firstName: string,
      lastName: string,
      inviteToken: string | undefined,
      password: string
    ) => {
      const res = await updateUser({
        variables: {
          userInput: {
            firstName,
            lastName,
            inviteToken,
            password,
          },
        },
      });

      const graphQlErrors = res?.errors;
      let user: User | undefined = undefined;
      if (isSsoUserError(graphQlErrors?.[0].message)) {
        onSsoUserDetected();
      } else if (
        graphQlErrors?.[0] &&
        isInvalidOtpError(graphQlErrors[0].message)
      ) {
        graphQlErrors[0].message =
          'This invite link has already been used. Please try a different way to login';
      } else if (res?.data && 'updateUser' in res.data) {
        user = res.data.updateUser as User;
      }

      return { res, graphQlErrors, user };
    },
    [onSsoUserDetected, updateUser]
  );

  const handleSignup = useCallback(
    async (formData: SignupFormSchema) => {
      log.info('Submitting signup form', { formData, query: router.query });
      const { email, password, firstName, lastName } = formData;
      const inviterId = (router.query.inviter as string) || undefined;
      /*
       if the invitation comes with the route pattern as /invitation/team/[teamInviteToken]
       then we get it from the query params with the name teamInviteToken, otherwise we get it
       from the query params with the name inviteToken
      */
      const inviteToken = router.query.invitation
        ? (router.query.invitation as string)
        : router.query.teamInviteToken
        ? (router.query.teamInviteToken as string)
        : undefined;
      const otp = (router.query.otp as string) || undefined;

      let graphQlErrors: readonly GraphQLError[] | undefined;
      let user: User | undefined;
      if (otp) {
        let res: FetchResult<
          OneTimePasswordLoginMutation | UpdateUserMutation
        > = await loginWithOtp({
          variables: {
            oneTimePasswordLoginInput: {
              email: emailFromQueryParams,
              otp,
              inviteToken,
            },
          },
        });
        graphQlErrors = res?.errors;
        if (!graphQlErrors?.length) {
          ({ res, graphQlErrors, user } = await handleUserUpdate(
            firstName,
            lastName,
            inviteToken,
            password
          ));
        }
      } else {
        const res = await createUser({
          variables: {
            userInput: {
              email,
              firstName,
              lastName,
              password,
              inviterId,
              inviteToken,
              boardId,
            },
          },
        });

        user = res?.data?.createUser as User;
        graphQlErrors = res?.errors;
      }

      if (graphQlErrors?.length) {
        log.warn('Handled signup form GraphQL error', graphQlErrors);
        setError(graphQlErrors[0].message);
        return;
      }

      log.info('Successfully signed up', user);

      onSuccess(user as User);
    },
    [
      router.query,
      onSuccess,
      loginWithOtp,
      emailFromQueryParams,
      handleUserUpdate,
      createUser,
      boardId,
    ]
  );

  return (
    <motion.div
      initial={{ x: 50, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: -50, opacity: 0 }}
      transition={{ duration: 0.15, ease: 'easeOut' }}
    >
      <SpkHead title="Sign Up" />

      <Card
        w={{ base: '95%', sm: '100%', md: 460 }}
        p={{ base: 6, md: 10 }}
        mb={0}
        mx="auto"
      >
        <Image
          src={illustrationHello}
          alt="Hello from ScatterSpoke"
          maxW="100%"
          height="auto"
        />
        <VStack>
          <VStack spacing={1}>
            <Heading
              size="md"
              fontWeight={500}
              color="gray.700"
              textAlign="center"
            >
              {greeting}
            </Heading>
            <Text fontSize="sm" color="gray.500" textAlign="center">
              Create a free account to get started with ScatterSpoke
              {onSelectMethod && (
                <>
                  {' '}
                  or{' '}
                  <Link onClick={() => onSelectMethod()}>
                    choose another method
                  </Link>
                </>
              )}
              .
            </Text>
          </VStack>
        </VStack>
        <VStack
          spacing={4}
          w="full"
          as="form"
          onSubmit={handleSubmit(handleSignup)}
        >
          <FormControl isInvalid={!isValid}>
            <VStack spacing={4}>
              <HStack
                spacing={4}
                w="full"
                flexDirection={{ base: 'column', sm: 'row' }}
                alignItems="flex-start"
              >
                <FormControl isInvalid={Boolean(errors.firstName)}>
                  <FormLabel htmlFor="firstName">First name</FormLabel>
                  <Input
                    id="firstName"
                    {...register('firstName', rules.firstName)}
                  />
                  <FormErrorMessage>
                    {errors?.firstName?.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={Boolean(errors.lastName)}>
                  <FormLabel htmlFor="lastName">Last name</FormLabel>
                  <Input
                    id="lastName"
                    {...register('lastName', rules.lastName)}
                  />
                  <FormErrorMessage>
                    {errors?.lastName?.message}
                  </FormErrorMessage>
                </FormControl>
              </HStack>
              <FormControl isInvalid={Boolean(errors.email)}>
                <FormLabel htmlFor="email">Work email</FormLabel>
                <Input
                  id="email"
                  type="email"
                  disabled={!!emailFromQueryParams}
                  {...register('email', rules.email)}
                />
                <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={Boolean(errors.password)}>
                <FormLabel htmlFor="password">Password</FormLabel>
                <Input
                  id="password"
                  type="password"
                  {...register('password', rules.password)}
                />
                <FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
              </FormControl>
            </VStack>
          </FormControl>
          {error && (
            <FormControl isInvalid={Boolean(error)} m="0">
              <FormErrorMessage>{error}</FormErrorMessage>
            </FormControl>
          )}
          <Button w="full" isLoading={isSubmitting} type="submit">
            Get Started
          </Button>
        </VStack>
        <Text
          fontSize={{ base: 'xs', sm: 'sm' }}
          color="gray.500"
          textAlign="center"
          mt={2}
        >
          By clicking the &apos;Get Started&apos; button, you agree to
          ScatterSpoke&apos;s{' '}
          <SpkLink href={SPOKE_TERMS_OF_SERVICE} passHref>
            <Link color="gray.900" fontWeight="500">
              Terms of Service
            </Link>
          </SpkLink>{' '}
          and{' '}
          <SpkLink href={SPOKE_PRIVACY_POLICY} passHref>
            <Link color="gray.900" fontWeight="500">
              Privacy Policy
            </Link>
          </SpkLink>
        </Text>
        {onContinueWithEmailPassword && (
          <>
            <Divider />
            <HStack
              spacing={{ base: 2, sm: 4 }}
              w="full"
              justifyContent="center"
              flexDirection={{ base: 'column', sm: 'row' }}
              alignItems="center"
            >
              <Text fontSize="sm" color="gray.500">
                Already have an account?
              </Text>
              <Button
                onClick={() => onContinueWithEmailPassword()}
                as="a"
                size="sm"
                bg="gray.50"
                variant="outlineGray"
                width={{ base: 'full', sm: 'auto' }}
              >
                Sign In
              </Button>
            </HStack>
          </>
        )}
      </Card>
    </motion.div>
  );
};
