import { StackProps, Skeleton } from '@chakra-ui/react';
import { VscFeedback } from 'react-icons/vsc';
import { BsPlus } from 'react-icons/bs';
import { AsyncFeedbackConfigList } from './AsyncFeedbackConfigList';
import {
  Button,
  Flex,
  Icon,
  Text,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Heading,
  HStack,
  VStack,
  Image,
  useAssets,
  Link,
} from '@spoke/common';
import { BoardAsyncSchedule } from '@spoke/graphql';
import { useCurrentOrgLockdown } from '@spoke/user';

type AsyncFeedbackListModalBodyProps = StackProps & {
  asyncFeedbackConfigs: BoardAsyncSchedule[];
  onCreateConfig: () => void;
  onConfigClick: (config: BoardAsyncSchedule) => void;
  onDeleteConfig: (configId: string) => void;
  onTriggerClick: (configId: string) => void;
  isLoading?: boolean;
};

const LoadingSkeletons = () => (
  <VStack spacing={3} w="full">
    {[1, 2, 3].map((i) => (
      <Skeleton key={i} w="full" h="100px" borderRadius="md" />
    ))}
  </VStack>
);

export const AsyncFeedbackListModalBody = ({
  asyncFeedbackConfigs,
  onCreateConfig,
  onConfigClick,
  onDeleteConfig,
  onTriggerClick,
  isLoading,
  ...rest
}: AsyncFeedbackListModalBodyProps) => {
  const { illustrationYellowPage } = useAssets();
  const [isOrgLocked, isTrialExpired, isOrgFree] = useCurrentOrgLockdown();

  return (
    <ModalContent p={{ base: 4, md: 8 }} maxW={700}>
      <ModalCloseButton />
      <ModalHeader p={0} mb={4}>
        {(asyncFeedbackConfigs.length > 0 || isLoading) && (
          <HStack>
            <Icon as={VscFeedback} w={6} h={6} />
            <Heading mb={1} fontSize={{ base: 20, md: 24 }} color="gray.900">
              Team Feedback Schedule
            </Heading>
          </HStack>
        )}
      </ModalHeader>
      <ModalBody p={0}>
        {isLoading ? (
          <LoadingSkeletons />
        ) : asyncFeedbackConfigs.length > 0 ? (
          <VStack spacing={2} align="start" w="full">
            <Text fontSize="14" color="gray.500" mt={4}>
              Automate feedback collection from your team for your team standups
              and retrospectives.{' '}
              <Link
                color="primary.500"
                href="https://www.scatterspoke.com/kb-cat/asynchronous-feedback"
                isExternal
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more
              </Link>{' '}
              about the benefits of automating feedback.
            </Text>
            <AsyncFeedbackConfigList
              asyncFeedbackConfigs={asyncFeedbackConfigs}
              onConfigClick={onConfigClick}
              onDeleteConfig={onDeleteConfig}
              onTriggerClick={onTriggerClick}
              maxHeight="500px"
            />
            <Button
              fontSize="15px"
              iconSpacing={1}
              leftIcon={<Icon as={BsPlus} color="white" w={7} h={7} />}
              onClick={onCreateConfig}
              alignSelf={{ base: 'stretch', md: 'end' }}
              disabled={isOrgLocked || isTrialExpired || isOrgFree}
            >
              Create
            </Button>
          </VStack>
        ) : (
          <VStack w="full">
            <VStack
              textAlign="center"
              maxW={410}
              px={{ base: 2, md: 4 }}
              spacing={4}
            >
              <Flex direction="column" alignItems="center">
                <Image
                  src={illustrationYellowPage}
                  alt="No feedback created yet"
                  w={{ base: 150, md: 200 }}
                  mb={5}
                />
                <Heading size="md" color="gray.700" fontWeight={500} mb={2}>
                  No feedback scheduled
                </Heading>
                <Text color="gray.500" fontSize={14}>
                  Getting feedback regularly from your team can help you
                  identify issues and improve your processes.
                </Text>
              </Flex>
              <Button
                onClick={onCreateConfig}
                fontSize="15px"
                iconSpacing={1}
                leftIcon={<Icon as={BsPlus} color="white" w={7} h={7} />}
                disabled={isOrgLocked || isTrialExpired || isOrgFree}
                w={{ base: 'full', md: 'auto' }}
              >
                Schedule Team Feedback
              </Button>
            </VStack>
          </VStack>
        )}
      </ModalBody>
    </ModalContent>
  );
};
