import { format } from 'date-fns';
import { RRule, Weekday } from 'rrule';
import { CustomRecurrenceState, TimeValue } from './schduler.ui.types';
import { DateTime } from 'luxon';

const weekdayOrder = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];

const getWeekdayNames = (weekdays: any[]): string => {
  const sortedWeekdays = [...weekdays].sort((a, b) => {
    const aIndex = weekdayOrder.indexOf(a.toString());
    const bIndex = weekdayOrder.indexOf(b.toString());
    return aIndex - bIndex;
  });

  const days = sortedWeekdays.map((day) => {
    switch (day.toString()) {
      case 'MO':
        return 'Mo';
      case 'TU':
        return 'Tu';
      case 'WE':
        return 'We';
      case 'TH':
        return 'Th';
      case 'FR':
        return 'Fr';
      case 'SA':
        return 'Sa';
      case 'SU':
        return 'Su';
      default:
        return '';
    }
  });

  if (days.length === 0) return '';
  if (days.length === 1) return days[0];
  if (days.length === 2) return `${days[0]} and ${days[1]}`;
  return `${days.slice(0, -1).join(', ')}, and ${days[days.length - 1]}`;
};

const getFrequencyText = (frequency: number): string => {
  switch (frequency) {
    case RRule.DAILY:
      return 'day';
    case RRule.WEEKLY:
      return 'week';
    case RRule.MONTHLY:
      return 'month';
    case RRule.YEARLY:
      return 'year';
    default:
      return '';
  }
};

export const formatTimeDisplay = (time: TimeValue): string =>
  `${time.hours}:${time.minutes} ${time.ampm}`;

export const getCustomRuleDescription = (
  customState: CustomRecurrenceState,
  isRecurring: boolean,
  startDate: Date,
  time: TimeValue
): string => {
  if (!isRecurring) {
    return `Once on ${DateTime.fromJSDate(startDate).toFormat('MMMM d, yyyy')} at ${
      time.hours
    }:${time.minutes} ${time.ampm}`;
  }

  const frequencyText = getFrequencyText(customState.frequency);
  const intervalText =
    customState.interval === 1 ? '' : ` ${customState.interval}`;

  let description = `Every${intervalText} ${frequencyText}`;

  if (customState.frequency === RRule.WEEKLY && customState.weekdays.length > 0) {
    const weekdayNames = customState.weekdays
      .map((day) => day.toString().slice(0, 2))
      .join(', ');
    description += ` on ${weekdayNames}`;
  } else if (customState.frequency === RRule.YEARLY) {
    description += ` on ${DateTime.fromJSDate(startDate).toFormat('MMMM d')}`;
  } else if (customState.frequency === RRule.MONTHLY) {
    description += ` on the ${DateTime.fromJSDate(startDate).toFormat('d')}${getDayOrdinal(parseInt(DateTime.fromJSDate(startDate).toFormat('d')))}`;
  }

  description += ` at ${time.hours}:${time.minutes} ${time.ampm}`;
  return description;
};

// Helper function to get ordinal suffix for day of month
const getDayOrdinal = (day: number): string => {
  if (day > 3 && day < 21) return 'th';
  switch (day % 10) {
    case 1:  return 'st';
    case 2:  return 'nd';
    case 3:  return 'rd';
    default: return 'th';
  }
};

export const getRRuleDisplayText = (rruleString: string): string => {
  try {
    // Parse the RRULE string
    const [dtstartSection, rruleSection] = rruleString.split(';RRULE:');
    const dtstart = dtstartSection.match(
      /DTSTART;TZID=([^:]+):(\d{4})(\d{2})(\d{2})T(\d{2})(\d{2})(\d{2})/
    );
    
    if (!dtstart) return 'Invalid schedule';

    const [_, timezone, year, month, day, hour, minute] = dtstart;
    const dateTime = DateTime.fromObject(
      {
        year: parseInt(year),
        month: parseInt(month),
        day: parseInt(day),
        hour: parseInt(hour),
        minute: parseInt(minute),
      },
      { zone: timezone }
    );

    // Convert hour to 12-hour format
    const isPM = dateTime.hour >= 12;
    const hour12 = dateTime.hour === 0 ? 12 : dateTime.hour > 12 ? dateTime.hour - 12 : dateTime.hour;
    const timeValue: TimeValue = {
      hours: hour12.toString(),
      minutes: dateTime.minute.toString().padStart(2, '0'),
      ampm: isPM ? 'PM' : 'AM',
    };

    // Parse the RRULE part
    const rule = RRule.fromString('RRULE:' + rruleSection);
    const isRecurring = rule.options.count !== 1;

    // Create custom state from rule options
    const customState: CustomRecurrenceState = {
      frequency: rule.options.freq,
      interval: rule.options.interval || 1,
      weekdays: (rule.options.byweekday || []).map((day: number) => {
        switch (day) {
          case 0:
            return RRule.MO;
          case 1:
            return RRule.TU;
          case 2:
            return RRule.WE;
          case 3:
            return RRule.TH;
          case 4:
            return RRule.FR;
          case 5:
            return RRule.SA;
          case 6:
            return RRule.SU;
          default:
            return RRule.MO;
        }
      }),
    };

    const description = getCustomRuleDescription(
      customState,
      isRecurring,
      dateTime.toJSDate(),
      timeValue
    );

    return `${description} (${timezone})`;
  } catch (error) {
    return 'Invalid schedule';
  }
};
