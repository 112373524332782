import { FC } from 'react';
import { BsCalendar, BsClockHistory, BsThreeDots } from 'react-icons/bs';
import { VscFeedback } from 'react-icons/vsc';
import {
  SpkTime,
  Flex,
  VStack,
  Badge,
  HStack,
  Icon,
  Divider,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
  isNullish,
  Text,
  Image,
} from '@spoke/common';
import { Board } from '@spoke/graphql';

type RetroHistoryCardProps = {
  board: Board;
  onSelectBoard: (
    id: string,
    isCanvas: boolean,
    isAsyncFeedback: boolean,
    board: Board
  ) => unknown;
  onCloneBoard: (board: Board) => unknown;
  onMoveBoard: (board: Board) => unknown;
  onArchiveBoard: (board: Board) => unknown;
  onToggleBoardPrivacy: (board: Board) => unknown;
};
export const RetroHistoryCard: FC<RetroHistoryCardProps> = ({
  board,
  onSelectBoard,
  onCloneBoard,
  onToggleBoardPrivacy,
  onMoveBoard,
  onArchiveBoard,
}) => {
  const formattedUpdatedAt: string = SpkTime.timeAgo(
    board.updatedAt ?? new Date()
  );

  const formattedParticipationRate: string | null = !isNullish(
    board.participationRate
  )
    ? ((board.participationRate as number) * 100).toFixed(0)
    : null;

  const formatIconUrl = board.format?.iconImageUrl ?? '';

  const toggleBoardPrivacy = () => onToggleBoardPrivacy(board);

  const cloneBoard = () => onCloneBoard(board);

  const moveBoard = () => onMoveBoard(board);

  const archiveBoard = () => onArchiveBoard(board);

  const getBoardTypeLabel = (boardLable: Board): string => {
    if (boardLable.feedbackType === 'STANDUP') {
      return 'Standup';
    } else if (boardLable.type === 'COLUMN') {
      return boardLable.boardAsyncScheduleId
        ? 'Async Feedback'
        : 'Column Retro';
    } else if (boardLable.type === 'CANVAS') {
      return 'Whiteboard';
    } else {
      return 'Unknown Type';
    }
  };

  return (
    <Box
      borderRadius="md"
      borderColor="gray.200"
      borderWidth="1px"
      display="flex"
      flexDirection="column"
      flex="1 0 31%"
      maxW={`${(1 / 3) * 100 - 0.5}%`}
      overflow="hidden"
      backgroundColor="white"
      boxShadow="extralight"
      _hover={{ boxShadow: 'md' }}
      transition="box-shadow 0.1s ease-out"
      flexGrow={1}
    >
      <Flex
        flex="1"
        as="button"
        flexDirection="column"
        onClick={() =>
          onSelectBoard(
            board.id,
            board.type === 'CANVAS',
            !!board.boardAsyncScheduleId,
            board
          )
        }
      >
        <Text
          fontFamily="museo-sans-rd-500"
          fontSize={17}
          textAlign="left"
          w="full"
          px={4}
          pt={4}
          noOfLines={1}
          pr={20}
        >
          {board.name}
        </Text>
        <Flex p={4} pt={3} fontFamily="proxima-nova-md" flex="1">
          {formatIconUrl && !board.boardAsyncScheduleId && (
            <Image
              src={formatIconUrl}
              alt={board.formatSlug ?? ''}
              w="48px"
              h="48px"
              mr={2}
            />
          )}
          {board.boardAsyncScheduleId && board.feedbackType === 'STANDUP' && (
            <Box
              borderRadius="md"
              backgroundColor="#ffcc3e"
              p={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
              mr={2}
            >
              <Icon as={BsClockHistory} w={9} h={8} />
            </Box>
          )}
          <VStack alignItems="flex-start">
            <Flex>
              <Badge variant="outlineRounded" mr={1}>
                {getBoardTypeLabel(board)}
              </Badge>
              <Badge variant="outlineRounded">
                {board.public ? 'Public' : 'Private'}
              </Badge>
            </Flex>
            <HStack alignItems="flex-start">
              <Icon ml={1} as={BsCalendar} color="gray.500" />
              <Text color="gray.500" fontSize="xs" textAlign="left">
                Last edit: {formattedUpdatedAt}
              </Text>
            </HStack>
          </VStack>
        </Flex>
      </Flex>

      <Divider />
      <Flex
        justifyContent="space-between"
        alignItems="center"
        fontFamily="proxima-nova-md"
        py="6px"
        px={5}
      >
        <Text fontSize="xs" color="gray.500" h={4}>
          {formattedParticipationRate}% response rate &#x25cf;{' '}
          {board.totalCards} responses
        </Text>
        <Menu>
          <MenuButton h="fit-content">
            <Icon as={BsThreeDots} w={5} h={5} color="gray.500" pt="4px" />
          </MenuButton>
          <MenuList>
            <MenuItem onClick={toggleBoardPrivacy}>
              Make {board?.public ? 'private' : 'public'}
            </MenuItem>
            <MenuItem onClick={cloneBoard}>Clone</MenuItem>
            <MenuItem onClick={moveBoard}>Move</MenuItem>
            <MenuItem onClick={archiveBoard}>Archive</MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Box>
  );
};
