import { RRule } from 'rrule';
import { RecurrenceOption } from './schduler.ui.types';

export const WEEKDAYS = [
  { value: RRule.MO, label: 'Mo' },
  { value: RRule.TU, label: 'Tu' },
  { value: RRule.WE, label: 'We' },
  { value: RRule.TH, label: 'Th' },
  { value: RRule.FR, label: 'Fr' },
  { value: RRule.SA, label: 'Sa' },
  { value: RRule.SU, label: 'Su' },
];

export const RECURRENCE_OPTIONS: RecurrenceOption[] = [
  {
    id: 'every-weekday',
    label: 'Every Weekday (Monday to Friday)',
    value: {
      freq: RRule.WEEKLY,
      byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR],
    },
  },
  {
    id: 'biweekly-tue-thu',
    label: 'Bi-weekly on Tuesday and Thursday',
    value: { freq: RRule.WEEKLY, interval: 2, byweekday: [RRule.TU, RRule.TH] },
  },
  {
    id: 'weekly-tuesday',
    label: 'Weekly on Tuesday',
    value: { freq: RRule.WEEKLY, byweekday: [RRule.TU] },
  },
  {
    id: 'biweekly-tuesday',
    label: 'Every other week on Tuesday',
    value: { freq: RRule.WEEKLY, interval: 2, byweekday: [RRule.TU] },
  },
  {
    id: 'monthly-last-tuesday',
    label: 'Monthly on last Tuesday',
    value: { freq: RRule.MONTHLY, byweekday: [RRule.TU.nth(-1)] },
  },
  {
    id: 'quarterly-last-tuesday',
    label: 'Quarterly on Last Tuesday',
    value: { freq: RRule.MONTHLY, interval: 3, byweekday: [RRule.TU.nth(-1)] },
  },
];

export const FREQUENCY_OPTIONS = [
  { label: 'Day', value: RRule.DAILY },
  { label: 'Week', value: RRule.WEEKLY },
  { label: 'Month', value: RRule.MONTHLY },
  { label: 'Year', value: RRule.YEARLY },
];
